import { render, staticRenderFns } from "./contract-info-echo.vue?vue&type=template&id=048b5f82&scoped=true"
import script from "./contract-info-echo.vue?vue&type=script&lang=js"
export * from "./contract-info-echo.vue?vue&type=script&lang=js"
import style0 from "./contract-info-echo.vue?vue&type=style&index=0&id=048b5f82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048b5f82",
  null
  
)

export default component.exports