<template>
  <div>
    <div class="contract__cont">
      <p>批量修改订单合同主体：</p>
      <el-select v-model="id" filterable placeholder="选择可用主体" style="display: flex;width: 250px;">
        <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="modify" size="small"
        style="border: 1px solid #AB0033; color: #AB0033; margin-left: 10px; height: 30px">确定修改</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractInfo',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      default: 'NORMAL',
    },
  },
  data() {
    return {
      id: '',
    };
  },
  methods: {
    modify() {
      this.$emit('click', { distributorContractInfoId: this.id, brandType: this.type, type: 'all' });
      this.id = '';
    }
  }
};
</script>

<style lang="scss" scoped>
.contract__cont {
  display: -webkit-box;
  // width: 500px;
  margin-left: 10px;
}
</style>