<template>
  <div class="app-container wrap">
    <div class="tips">
      <p>大贸一件代发导入说明</p>
      <p>
        A、该功能主要用于商家线下通过其他方式销售了商品产生的订单或是想批量代理下单来使用的；
      </p>
      <p>B、大贸一件代发的商品支持的为我们的普通商品（非海淘商品）；</p>
      <p>C、一件代发目前仅支持使用余额或是授信额度支付；</p>
    </div>
    <div class="table-container" v-if="!showTable">
      <div class="top-container">
        <UploadExcel @getData="getData"></UploadExcel>
        <el-button
          @click="onDownload"
          size="small"
          style="border: 1px solid #AB0033; color: #AB0033; margin-left: -310px; height: 32px"
          >下载模板</el-button
        >
        <div style="flex: 1"></div>
        <router-link
          to="/syoung/introduce/drop_shipping_order"
          class="link-type"
          >查看帮助文档</router-link
        >
      </div>
    </div>
    <div class="table-container" v-if="showTable">
      <div class="top-container">
        <div class="top-container-left">
          <UploadExcel @getData="getData" btnName="重新上传"></UploadExcel>
        </div>
        <el-button
          @click="onDownload"
          size="small"
          style="border: 1px solid #AB0033; color: #AB0033; margin-left: -310px; height: 32px"
          >下载模板</el-button
        >
      </div>
      <div class="order-num-detail order-num-bg">
        <span>
          本次导入
          <span class="red">{{ totalDetail.orderQuantity }}</span>
          笔订单,上传成功
          <span class="red">{{ totalDetail.orderSuccessQuantity }}</span
          >条,失败
          <span class="red">{{ totalDetail.orderFailQuantity }}</span>
          条,批次号：{{ batchNo }}
          <el-button
            :loading="exportLoading"
            @click="onExport"
            type="text"
            v-if="totalDetail.orderFailQuantity"
            >下载失败订单</el-button
          >
        </span>
      </div>
    </div>
    <div class="table-container" v-if="showTable">
      <el-checkbox
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        v-if="options.length > 0"
        v-model="checkAll"
        >全选</el-checkbox
      >
      <el-button @click="handleDeleteIds" type="text" v-if="options.length > 0"
        >批量删除</el-button
      >
      <div class="table">
        <el-checkbox-group @change="handleCheckListChange" v-model="checkList">
          <table
            border="0"
            cellpadding="0"
            cellspacing="0"
            class="refund-list"
            style="width: 100%"
          >
            <thead class="table-head">
              <tr class="line">
                <th style="width: 10%">
                  <div class="cell">商品信息</div>
                </th>
                <th style="width: 10%">
                  <div class="cell">规格</div>
                </th>
                <th>
                  <div class="cell">批发价</div>
                </th>
                <th>
                  <div class="cell">数量</div>
                </th>
                <th>
                  <div class="cell">小计</div>
                </th>
                <th>
                  <div class="cell">操作</div>
                </th>
              </tr>
            </thead>
            <div class="table__title">
              <span class="table__title--title" v-if="listNormal.length !== 0"
                >自有仓商品</span
              >
              <!-- 批量修改订单合同主体 -->
              <contract-info :list="normalList" type="NORMAL" @click="modify" v-if="listNormal.length !== 0" />
            </div>
            <tbody
              :key="index"
              class="table-body"
              v-for="(item, index) in listNormal"
            >
              <tr class="line tips-container">
                <td class="tips-content" colspan="5">
                  <div class="cell">
                    <el-checkbox
                      :label="item.id"
                      v-if="item.status === 'IMPORT_FAIL'"
                      >买家订单号：{{ item.buyerOrderNo }}</el-checkbox
                    >
                    <span v-else>买家订单号：{{ item.buyerOrderNo }}</span>
                    <span class="consignee-info">收货信息：</span>
                    <span>{{ item.consigneeInfo.consignee }}&nbsp;&nbsp;</span>
                    <span>{{ item.consigneeInfo.mobile }}&nbsp;&nbsp;</span>
                    <span>{{ item.consigneeInfo.consigneeAddress }}</span>
                    <!-- 单次修改订单合同主体 -->
                    <contract-info-echo :list="normalList" :id.sync="item.contractInfo.id" type="NORMAL" class="consignee-subject" @click="modify" />
                  </div>
                </td>
                <td class="tips-content" colspan="1">
                  <div class="cell">
                    <p style="text-align: center">
                      <el-tooltip
                        :content="item.errMsgs"
                        placement="top"
                        v-if="item.errMsgs"
                      >
                        <span class="btn-error" type="text">数据错误</span>
                      </el-tooltip>
                      <el-button
                        @click="handleDelete(item.id)"
                        type="text"
                        v-if="item.status === 'IMPORT_FAIL'"
                        >删除</el-button
                      >
                    </p>
                  </div>
                </td>
              </tr>
              <tr class="line">
                <!-- 商品信息 -->
                <td class="good-img" style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap commodity-wrap--left"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <img :src="datas.imgUrl" class="table-img" />
                    <el-tooltip
                      effect="light"
                      :content="datas.commodityName"
                      placement="bottom-start"
                      ><p class="commo-ellipsis-2" v-if="datas.commodityName">
                        {{ datas.commodityName }}
                      </p></el-tooltip
                    >
                  </div>
                </td>
                <!-- 规格 -->
                <td style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <div class="commo-ellipsis">
                      <p v-if="datas.commoditySpecValue">
                        {{ datas.commoditySpecValue }}
                      </p>
                      <el-tooltip
                        effect="light"
                        :content="datas.skuId"
                        placement="bottom-start"
                        ><span> 规格标识: {{ datas.skuId }}</span></el-tooltip
                      >
                    </div>
                  </div>
                </td>
                <!-- 批发价 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.price }}
                  </div>
                </td>
                <!-- 数量 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    {{ datas.quantity }}
                  </div>
                </td>
                <!-- 金额 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.saleAmount }}
                  </div>
                </td>
                <!-- 操作 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap error-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <el-tooltip
                      :content="datas.errMsgs"
                      placement="top"
                      v-if="datas.errMsgs"
                    >
                      <span class="btn-error btn-error-margin" type="text"
                        >数据错误</span
                      >
                    </el-tooltip>
                  </div>
                </td>
              </tr>
              <div class="div-blank"></div>
            </tbody>
            <div class="table__title">
              <div v-if="listNormal.length !== 0" class="delivery_Fee">
                运费：¥{{ freightNormal }}
              </div>
              <span
                class="table__title--title"
                v-if="listInternation.length !== 0"
                >合作仓商品</span
              >
              <!-- 批量修改订单合同主体 -->
              <contract-info :list="internationList" type="INTERNATION" @click="modify" v-if="listInternation.length !== 0" />
            </div>
            <tbody
              :key="'table-body' + index"
              class="table-body"
              v-for="(item, index) in listInternation"
            >
              <tr class="line tips-container">
                <td class="tips-content" colspan="5">
                  <div class="cell">
                    <el-checkbox
                      :label="item.id"
                      v-if="item.status === 'IMPORT_FAIL'"
                      >买家订单号：{{ item.buyerOrderNo }}</el-checkbox
                    >
                    <span v-else>买家订单号：{{ item.buyerOrderNo }}</span>
                    <span class="consignee-info">收货信息：</span>
                    <span>{{ item.consigneeInfo.consignee }}&nbsp;&nbsp;</span>
                    <span>{{ item.consigneeInfo.mobile }}&nbsp;&nbsp;</span>
                    <span>{{ item.consigneeInfo.consigneeAddress }}</span>
                    <!-- 单次修改订单合同主体 -->
                    <contract-info-echo :list="internationList" :id.sync="item.contractInfo.id" type="INTERNATION" class="consignee-subject" @click="modify" />
                  </div>
                </td>
                <td class="tips-content" colspan="1">
                  <div class="cell">
                    <p style="text-align: center">
                      <el-tooltip
                        :content="item.errMsgs"
                        placement="top"
                        v-if="item.errMsgs"
                      >
                        <span class="btn-error" type="text">数据错误</span>
                      </el-tooltip>
                      <el-button
                        @click="handleDelete(item.id)"
                        type="text"
                        v-if="item.status === 'IMPORT_FAIL'"
                        >删除</el-button
                      >
                    </p>
                  </div>
                </td>
              </tr>
              <tr class="line">
                <!-- 商品信息 -->
                <td class="good-img" style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap commodity-wrap--left"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <img :src="datas.imgUrl" class="table-img" />
                    <el-tooltip
                      effect="light"
                      :content="datas.commodityName"
                      placement="bottom-start"
                      ><p class="commo-ellipsis-2" v-if="datas.commodityName">
                        {{ datas.commodityName }}
                      </p></el-tooltip
                    >
                  </div>
                </td>
                <!-- 规格 -->
                <td style="width: 10%">
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <div class="commo-ellipsis">
                      <p v-if="datas.commoditySpecValue">
                        {{ datas.commoditySpecValue }}
                      </p>
                      <el-tooltip
                        effect="light"
                        :content="datas.skuId"
                        placement="bottom-start"
                        ><span> 规格标识: {{ datas.skuId }}</span></el-tooltip
                      >
                    </div>
                  </div>
                </td>
                <!-- 批发价 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.price }}
                  </div>
                </td>
                <!-- 数量 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    {{ datas.quantity }}
                  </div>
                </td>
                <!-- 金额 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap price"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    ¥{{ datas.saleAmount }}
                  </div>
                </td>
                <!-- 操作 -->
                <td>
                  <div
                    :key="idx"
                    class="commodity-wrap error-wrap"
                    v-for="(datas, idx) in item.dropshippingOrderDetailVOList"
                  >
                    <el-tooltip
                      :content="datas.errMsgs"
                      placement="top"
                      v-if="datas.errMsgs"
                    >
                      <span class="btn-error btn-error-margin" type="text"
                        >数据错误</span
                      >
                    </el-tooltip>
                  </div>
                </td>
              </tr>
              <div class="div-blank"></div>
            </tbody>
          </table>
        </el-checkbox-group>
        <div class="footer" v-if="showTable">
          <div v-if="listInternation.length !== 0" class="fee">
            运费：¥{{ freightInternation }}
          </div>
          <p>
            本次导入
            <span class="red">{{ totalDetail.orderQuantity }}</span
            >笔订单,上传成功
            <span class="red">{{ totalDetail.orderSuccessQuantity }}</span
            >条,失败 <span class="red">{{ totalDetail.orderFailQuantity }}</span
            >条
            <el-button
              @click="onExport"
              type="text"
              v-if="totalDetail.orderFailQuantity"
              >下载失败订单</el-button
            >
          </p>
          <div class="footer__content">
            <p class="footer__content__title">订单汇总信息</p>
            <div class="total">
              <p>本次订购订单总数量：{{ totalDetail.orderQuantity }}笔</p>
              <p>商品总数量：{{ totalDetail.commodityQuantity }}件</p>
              <p>商品总金额：¥{{ totalDetail.saleAmount }}</p>
              <p class="footer__content__title">
                总运费：¥{{ totalDetail.deliveryFee }}
              </p>
              <p>
                <el-checkbox
                  v-model="virtualCreditChecked"
                  :disabled="totalDetail.usableVirtualCredit === 0"
                  >返利抵扣(剩余￥{{ totalDetail.usableVirtualCredit }})：-¥{{
                    totalDetail.virtualCredit
                  }}</el-checkbox
                >
              </p>
            </div>
            <p class="all-price">
              订单支付金额：<span>¥{{ totalDetail.payAmount }}</span>
            </p>
          </div>
        </div>
        <div class="btn-wrap">
          <p class="btn-wrap--left">
            <span>待付金额：</span>
            <span class="money">¥{{ totalDetail.payAmount }}</span>
          </p>
          <button
            :class="[
              'btn-no-radius',
              totalDetail.payAmount === 0
                ? 'btn-no-radius--disabled'
                : 'btn-no-radius--default',
            ]"
            :loading="submitLoding"
            @click="submit"
            type="primary"
            :disabled="totalDetail.payAmount === 0"
          >
            提交订单
          </button>
        </div>
        <div></div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      center
      title="提示"
      width="30%"
      class="dialog-word"
    >
      <p :key="index" v-for="(item, index) in errorArr">
        {{ index + 1 }}.{{ item }}
      </p>
      <span class="dialog-footer" slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button @click="dialogVisible = false" type="primary"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="goodsDialogVisible"
      center
      title="确认订单提示"
      width="570px"
    >
      <div class="dialog-detail">
        <p>本次订购订单总数量：{{ totalDetail.orderQuantity }}笔</p>
        <p>商品总数量：{{ totalDetail.commodityQuantity }}件</p>
        <p>采购总价：{{ totalDetail.saleAmount }}</p>
        <p>总运费：{{ totalDetail.deliveryFee }}</p>
        <p>订单总价：{{ totalDetail.totalAmount }}</p>
        <p class="price">返利总抵扣：-￥{{ virtualCreditChecked ? totalDetail.virtualCredit : 0 }}</p>
        <p class="price">待付总金额：￥{{ totalDetail.payAmount }}</p>
      </div>
      <div class="dialog-table" v-if="totalDetail.brandType=== 'ALL'">
        <el-row>
          <el-col :span="12">
            <div class="dialog-detail">
              <h4>自有仓订单情况</h4>
              <p>商品总数量：{{ totalDetail.selfOrderTotal.commodityQuantity }}件</p>
              <p>采购总价：{{ totalDetail.selfOrderTotal.saleAmount }}</p>
              <p>总运费：{{ totalDetail.selfOrderTotal.deliveryFee }}</p>
              <p>订单总价：{{ totalDetail.selfOrderTotal.totalAmount }}</p>
              <p>返利总抵扣：-￥{{ virtualCreditChecked ? totalDetail.selfOrderTotal.virtualCredit : 0 }}</p>
              <p>待付金额：￥{{ totalDetail.selfOrderTotal.payAmount }}</p>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="dialog-detail">
              <h4>合作仓订单情况</h4>
              <p>商品总数量：{{ totalDetail.internationOrderTotal.commodityQuantity }}件</p>
              <p>采购总价：{{ totalDetail.internationOrderTotal.saleAmount }}</p>
              <p>总运费：{{ totalDetail.internationOrderTotal.deliveryFee }}</p>
              <p>订单总价：{{ totalDetail.internationOrderTotal.totalAmount }}</p>
              <p>返利总抵扣：-￥{{ virtualCreditChecked ? totalDetail.internationOrderTotal.virtualCredit : 0 }}</p>
              <p>待付金额：￥{{ totalDetail.internationOrderTotal.payAmount }}</p>
            </div>
          </el-col>
        </el-row>
      </div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="goodsDialogVisible = false">取 消</el-button>
        <el-button @click="confirmOrder" type="primary"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import download from '@/utils/download';
import { parseTime } from '@/utils';
import Pay from '@/components/Pay';
import UploadExcel from '@/components/UploadExcel';
import ContractInfo from './components/contract-info.vue';
import ContractInfoEcho from './components/contract-info-echo.vue';
import {
  list,
  getTotal,
  handleDelete,
  handleDeleteIds,
  exportError,
  butorContractInfo,
  getListOrderAvalibleContractInfo,
} from '@/api/dropShippingOrder/list';
import { getSignUrl } from '@/api/checkout';
export default {
  name: 'dropShippingOrder',
  data() {
    return {
      freightNormal: '',
      freightInternation: '',
      fileList: [],
      goodsDialogVisible: false, // 是否展示确认订单
      checkAll: false, // 是否全选
      options: [], // 错误订单索引集合(所有的)
      checkList: [], // 选中的错误订单集合
      isIndeterminate: false, // 是否半选
      showTable: false, // 是否展示订单列表
      listLoading: false,
      submitLoding: false,
      exportLoading: false,
      busiSeq: '',
      batchNo: '', // 导入批次号
      list: [], // 导入的订单列表
      listNormal: [], // 自营品牌订单列表
      listInternation: [], // 国际品牌订单列表
      errorArr: [], // 导入表格错误信息数组
      dialogVisible: false, // 是否展示错误信息弹窗
      totalDetail: {}, // 单次导入的订单概况
      virtualCreditChecked: true, // 是否返利
      normalList: [], // 自营品牌订单合同主体
      internationList: [], // 国际品牌订单合同主体
      buyerOrderNos: [], // 买家订单号
    };
  },
  components: { UploadExcel, Pay, ContractInfo, ContractInfoEcho },
  watch: {
    virtualCreditChecked(val) {
      this.getTotal();
    },
  },
  methods: {
    // 充值成功之后刷新数据
    sureRefresh() {
      this.fetchData();
    },
    // 下载失败订单
    onExport() {
      this.exportLoading = true;
      exportError(this.batchNo, {
        busiSeq: this.busiSeq,
      })
        .then((res) => {
          download(
            res,
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            `一件代发错误订单-${parseTime(
              Date.now(),
              '{y}-{m}-{d}-{h}:{i}:{s}'
            )}.xlsx`
          );
        })
        .finally(() => {
          this.exportLoading = false;
        });
    },
    // 提交订单
    submit() {
      const {
        baseResult,
        orderFailQuantity
      } = this.totalDetail;
      const whiteList = ['0', 'DISTRIBUTOR_CODE_501', 'DISTRIBUTOR_CODE_502'];
      if (!whiteList.includes(baseResult.code)) {
        this.$message.error(baseResult.msg);
        return;
      }
      switch (baseResult.code) {
        case 'DISTRIBUTOR_CODE_501':
        case 'DISTRIBUTOR_CODE_502':
          this.$confirm(baseResult.msg, '温馨提示', {
            confirmButtonText: '去完善',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            this.$router.replace(`/syoung/register/step?supplementStatus=${baseResult.code === 'DISTRIBUTOR_CODE_501' ? 'NO' : 'WAIT'}`);
          });
        return;
      }
      this.submitLoding = true;
      if (orderFailQuantity !== 0) {
        this.$message.error(
          '订单中有【错误数据】，请先修正重新上传，再提交订单'
        );
        this.submitLoding = false;
        return;
      }
      if (this.list.length === 0) {
        this.$message.error('订单内容为空，请重新上传之后再提交');
        this.submitLoding = false;
        return;
      }
      this.goodsDialogVisible = true;
    },
    confirmOrder() {
      const postData = {
        bizType: 'DROP_SHIPPING',
        id: this.batchNo,
        payAmount: this.totalDetail.payAmount,
        isUseVirtualCredit: this.virtualCreditChecked ? '1' : '0',
        type: 'drop_shipping',
        brandType: this.totalDetail?.brandType || '',
        ownCompanyId: this.totalDetail?.ownCompanyId || ''
      };
      if (this.totalDetail.brandType === 'ALL') {
        postData.internationOrderPayAmount = this.totalDetail?.internationOrderTotal?.payAmount || 0;
        postData.selfOrderPayAmount = this.totalDetail?.selfOrderTotal?.payAmount || 0;
      } else {
        postData.selfOrderPayAmount = postData.internationOrderPayAmount = this.totalDetail?.payAmount || 0;
      }
      sessionStorage.setItem('payData', JSON.stringify(postData));
      this.$router.push(`/syoung/pay/list?type=drop_shipping`);
    },
    // 获取导入文件之后的导入批次号，并根据批次号请求订单列表数据
    getData(response, ...args) {
      // 如果导入不成功则弹出不成功提示
      const whiteList = ['0', 'UNSIGN001'];
      if (!whiteList.includes(response.code)) {
        this.$message.error(response.msg);
        return;
      }
      if (response.code === 'UNSIGN001') {
        this.$confirm('您还未签署合同，请立即签署合同', '提示', {
          confirmButtonText: '立即签署',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            getSignUrl().then((res) => {
              window.open(res.data);
            });
          })
          .catch(() => {});
        return;
      }
      if (response.code === '800') {
        this.$message.error(response.msg);
        return false;
      }
      // 再次上传的表格有错误信息的时候，全选框默认不勾选
      this.checkAll = false;
      // 获取导入批次号
      this.batchNo = response.data.completeSuccess
        ? response.data.batchNum
        : '';
      this.busiSeq = response.data.completeSuccess
        ? response.data.busiSeq
        : '';
      this.virtualCreditChecked = true;
      if (this.batchNo) {
        this.fetchData();
        return;
      }
      // 获取错误提示
      this.errorArr = response.data.failedItems.map((item) => item.error);
      this.dialogVisible = true;
    },
    // 复选框全选
    handleCheckAllChange(val) {
      this.checkList = val ? this.options : [];
      this.isIndeterminate = false;
    },
    // 复选框选择改变
    handleCheckListChange(value) {
      const checkedCount = value.length;
      this.checkAll = checkedCount === this.options.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.options.length;
    },
    // 下载模板
    onDownload() {
      this.$confirm('是否下载模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        window.location.href = `https://oss.syounggroup.com/bigfile/soyoung-zg/template/大贸一件代发模板.xlsx`;
      });
    },
    // 获取订单列表
    fetchData() {
      this.listLoading = true;
      list({ batchNo: this.batchNo })
        .then((response) => {
          this.list = response.data.map(item => ({
            ...item,
            contractInfo: item.contractInfo ? { ...item.contractInfo } : {
              id: '',
            },
          }));
          this.listNormal = [];
          this.listInternation = [];
          this.list.forEach((item, index) => {
            if (item.brandType === 'NORMAL') {
              this.listNormal.push(item);
            } else if (item.brandType === 'INTERNATION') {
              this.listInternation.push(item);
            }
          });
          let priceNormal = 0;
          this.listNormal.forEach((i) => {
            priceNormal += i.deliveryFee;
          });
          let priceInternation = 0;
          this.listInternation.forEach((i) => {
            priceInternation += i.deliveryFee;
          });
          this.freightNormal = priceNormal.toFixed(2);
          this.freightInternation = priceInternation.toFixed(2);
          const arr = [];
          response.data.forEach((item, index) => {
            // 只有当订单状态为IMPORT_FAIL时才可以有复选框可以选中
            if (item.status === 'IMPORT_FAIL') {
              arr.push(item.id);
            }
            this.options = arr;
            this.fileList = [];
          });
          this.getTotal();
          this.getNormalList();
          this.getInternationList();
          this.showTable = true;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    // 获取单次导入的概况
    getTotal() {
      const data = {
        batchNo: this.batchNo,
        isUseVirtualCredit: this.virtualCreditChecked ? '1' : '0',
      };
      getTotal(data).then((response) => {
        this.totalDetail = response.data;
      });
    },
    // 删除
    handleDelete(val) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
      })
        .then(() => {
          handleDelete(val)
            .then((response) => {
              this.fetchData();
            })
            .finally(() => {
              this.listLoading = false;
            });
          this.$message({
            type: 'success',
            message: '删除成功！',
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          });
        });
    },
    // 批量删除
    handleDeleteIds() {
      if (this.checkList.length > 0) {
        this.$confirm('确认要批量删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true,
        })
          .then(() => {
            handleDeleteIds(this.checkList)
              .then((response) => {
                this.fetchData();
              })
              .finally(() => {
                this.listLoading = false;
              });
            this.$message({
              type: 'success',
              message: '删除成功！',
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除',
            });
          });
        return;
      }
      this.$message({
        type: 'warning',
        message: '请先选择需要删除的内容',
      });
    },
    // 订单合同主体列表
    getOrderList(orderBrandType) {
      return new Promise((resolve) => {
        getListOrderAvalibleContractInfo({
          orderBrandType: orderBrandType
        })
          .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.id,
              label: item.merchantTypeName + '-' + item.contractInfoName,
            }));
            resolve(res);
          }
        });
      });
    },
    // 自有仓订单合同主体列表
    getNormalList() {
      this.getOrderList('NORMAL').then(res => {
        this.normalList = res;
      });
    },
    // 合作仓订单合同主体列表
    getInternationList() {
      this.getOrderList('INTERNATION').then(res => {
        this.internationList = res;
      });
    },
    // 批量修改订单合同主体
    modify({ distributorContractInfoId = '', brandType = '', type = '' }) {
      const { batchNo, listInternation, listNormal } = this;
      if (!distributorContractInfoId && (brandType === 'INTERNATION' || brandType === 'NORMAL')) {
        this.$message({
          message: '请选择订单合同主体',
          type: 'warning',
        });
        return;
      }
      const params = {
        batchNo,
        brandType,
        distributorContractInfoId,
      };
      // 批量修改合同主体 or 单个修改
      params.buyerOrderNos = brandType === 'INTERNATION'
        ? listInternation.filter(item => type === 'all' || item?.contractInfo?.id === distributorContractInfoId).map(({ buyerOrderNo }) => buyerOrderNo)
        : listNormal.filter(item => type === 'all' || item?.contractInfo?.id === distributorContractInfoId).map(({ buyerOrderNo }) => buyerOrderNo);
      butorContractInfo(params).then(res => {
        if(res.code === '0') {
          // this.$message({ message: '修改成功', type: 'success' });
          this.fetchData();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/goods-table.scss';
@import './styles';
.dialog-word {
  /deep/ .el-dialog__body {
    max-height: 70vh;
    overflow-y: scroll;
  }
}
.consignee-subject {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: #101010;
  margin-left: 10px;
}
.table__title {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dialog-detail {
  line-height: 21px;
  color: #333;
}
.price {
  color:#ff0000;
}
.dialog-table {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  background: rgba(16, 16, 16, 0.03);
}
</style>
