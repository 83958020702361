<template>
  <div>
    <p class="consignee-info-echo">
      <span style="flex: none;">订单合同主体：</span>
      <el-select v-model="distributorContractInfoId" filterable placeholder="选择可用主体" style="display: flex;width: 235px;" @change="modify">
        <el-option v-for="item in list" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-button @click="modify" size="small"
        style="border: 1px solid #AB0033; color: #AB0033; margin-left: 10px; height: 30px">确认修改</el-button>
    </p>
  </div>
</template>

<script>
export default {
  name: 'ContractInfoEcho',
  props: {
    list: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      default: 'NORMAL',
    },
    id: String,
  },
  data() {
    return {
      distributorContractInfoId: '',
    };
  },
  watch: {
    id(val) {
      if (val !== this.distributorContractInfoId) {
        this.distributorContractInfoId = val;
      }
    }
  },
  mounted() {
    this.distributorContractInfoId = this.id;
  },
  methods: {
    modify() {
      this.$emit('update:id', this.distributorContractInfoId);
      this.$emit('click', { distributorContractInfoId: this.distributorContractInfoId, brandType: this.type, type: 'single' });
    }
  }
};
</script>

<style lang="scss" scoped>
.consignee-info-echo {
  display: inline-flex;
  align-items: center;
}
</style>